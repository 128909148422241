import { useStaticQuery, graphql } from "gatsby"
import groupBy from "lodash.groupby"

const groupByMonth = (courses) => groupBy(courses, (course) => course.month)

interface RawCourse {
  date: Date
  place: string
  type: string
}

export interface CourseWithMonth extends RawCourse {
  month: number
}

interface ResultObject {
  prvaPomoc: Record<string, CourseWithMonth[]>
  kurzB: Record<string, CourseWithMonth[]>
  kurzA: Record<string, CourseWithMonth[]>
}

export const useUpcomingGrouppedCourses = () => {
  const {
    allStrapiRealCourse: { courses: rawCourses },
  } = useStaticQuery(graphql`
    query upcomingCoursesQuerys {
      allStrapiRealCourse(sort: { order: ASC, fields: date }) {
        courses: nodes {
          date
          place
          type
        }
      }
    }
  `)

  const coursesWithMonth: CourseWithMonth[] = rawCourses.map((course) => ({
    ...course,
    month: new Date(course.date).getMonth(),
  }))

  const firstAidCourses = coursesWithMonth.filter(
    (course) => course.type === "kurzPrvejPomoci"
  )

  const coursesOfTypeB = coursesWithMonth.filter(
    (course) => course.type === "kurzB" && new Date(course.date) > new Date()
  )

  const coursesOfTypeA: CourseWithMonth[] = coursesWithMonth.filter(
    (course) => course.type === "kurzA" && new Date(course.date) > new Date()
  )

  const coursesGroupedByTypeOfCourse: ResultObject = {
    prvaPomoc: groupByMonth(firstAidCourses),
    kurzB: groupByMonth(coursesOfTypeB),
    kurzA: groupByMonth(coursesOfTypeA),
  }

  return { coursesGroupedByTypeOfCourse }
}
