import React from "react"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import CoursesTable from "../components/Courses/components/CoursesTable"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { useUpcomingGrouppedCourses } from "../components/Courses/hooks/useUpcomingGrouppedCourses"
import { Section } from "../components/shared/Layout/components/Section/Section"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"

const _Intro = styled.div`
  margin-bottom: 50px;
`
const TableWrapp = styled.div`
  margin-bottom: 40px;
`
const CoursesSection = styled(Section)``

const CoursesPage = () => {
  const { coursesGroupedByTypeOfCourse: courses } = useUpcomingGrouppedCourses()

  return (
    <Layout fluid={true}>
      <SEO
        title="Kurzy"
        description="Ponuka kurzov autoškoly Hron. Vyberte si kurz a neváhajte sa k nám prihlásiť. Vodičské oprávnenie skupiny B, Kurz prvej pomoci."
      />
      <CoursesSection>
        <Container fluid={false}>
          <PageHeader header="Kurzy" />

            {Object.keys(courses.kurzA).length > 0 && (
                <TableWrapp
                data-sal-duration="650"
                data-sal="slide-up"
                data-sal-easing="ease-in"
                data-sal-delay="200"
            >
                <h2>Vodičské oprávnenie skupiny A</h2>
                {Object.keys(courses.kurzA).map((month: string) => (
                    <CoursesTable key={month} rows={courses.kurzA[month]} />
                ))}
            </TableWrapp>
            )}

          <TableWrapp
            data-sal-duration="650"
            data-sal="slide-up"
            data-sal-easing="ease-in"
            data-sal-delay="200"
          >
            <h2>Vodičské oprávnenie skupiny B</h2>
            {Object.keys(courses.kurzB).map((month: string) => (
              <CoursesTable key={month} rows={courses.kurzB[month]} />
            ))}
          </TableWrapp>

          {Object.keys(courses.prvaPomoc).length > 0 && (
            <TableWrapp>
              <h2>Kurz prvej pomoci</h2>
              {Object.keys(courses.prvaPomoc).map((month: string, index) => (
                <div
                  data-sal-duration="300"
                  data-sal="slide-up"
                  data-sal-delay={index * 50}
                  data-sal-easing="ease"
                  key={month}
                >
                  <CoursesTable rows={courses.prvaPomoc[month]} />
                </div>
              ))}
            </TableWrapp>
          )}
        </Container>
      </CoursesSection>
    </Layout>
  )
}

export default CoursesPage
