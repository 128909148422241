import Paper from "@material-ui/core/Paper"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import dateFormat from "dateformat"
import React from "react"
import {CourseWithMonth} from "../hooks/useUpcomingGrouppedCourses"
import {Month, StyledTable, StyledTableCell, StyledTableContainer, StyledTableHead, StyledTableRow,} from "./styled/All"

dateFormat.i18n = {
    dayNames: [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ],
    monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Január",
        "Február",
        "Marec",
        "Apríl",
        "Máj",
        "Jún",
        "Júl",
        "August",
        "September",
        "Október",
        "November",
        "December",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
}

export interface CoursesTableRow {
    month: string
    date: string
    time: string
}

interface Props {
    rows: CourseWithMonth[]
}

const place = "Centrum voľného času Domino Bela IV. 1567/6 960 01 Zvolen"
const place2 = "Stretnutie na AS Zvolen, odkiaľ ide celá skupina spoločne na kurz."

const kurzTypePlaceMap = {
    kurzB: place,
    kurzA: place,
    prvaPomoc: place2,

}

export default function CustomizedTables(props: Props) {
    const {rows} = props

    return (
        <StyledTableContainer component={Paper}>
            <StyledTable aria-label="customized table">
                <StyledTableHead>
                    <TableRow>
                        <StyledTableCell component="th" align="left">
                            <Month>{dateFormat(rows[0].Date, "mmmm")}</Month>
                        </StyledTableCell>
                        <StyledTableCell component="th" align="right"></StyledTableCell>
                        <StyledTableCell component="th" align="right"></StyledTableCell>
                    </TableRow>
                </StyledTableHead>
                <TableBody>
                    {rows.map((row, index) => (

                        <StyledTableRow key={index}>
                            <StyledTableCell scope="row">
                                <h3>{dateFormat(row.date, "dd.mm.yyyy")}</h3>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <h3> {dateFormat(row.date, "HH:MM")}</h3>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {kurzTypePlaceMap[row.type]}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </StyledTableContainer>
    )
}
