import styled from "styled-components"
import {
  TableContainer,
  TableCell,
  TableRow,
  Table,
  TableHead,
} from "@material-ui/core"

export const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 25px;
`
export const StyledTableCell = styled(TableCell)`
  padding: 12px !important;
`
export const StyledTableRow = styled(TableRow)``

export const StyledTable = styled(Table)`
  min-width: 700px;
`
export const StyledTableHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.color.primary};
`

export const Month = styled.h3`
  color: ${({ theme }) => theme.color.white};
`
